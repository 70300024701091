footer {
    font-family: 'HelveticaNeueW01-55Roma', sans-serif;
    background-color: #999999;
    padding: 25px;
    margin-top: auto;
}

footer p {
    color: white;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
}

footer p a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
}

footer p a:hover {
    text-decoration: underline;
}
