
.leaflet-container{
    height: 610px;
    width: 100%;
    position: relative;
    margin-bottom: 100px;
    z-index: 0;
}

.map {
    height: 100%;
}

.map-page-wrapper{
    position: relative;
    margin-top: 100px;
}

.kiosks-container {
    padding: 5px;
    background: #f2f2f2;
}

.map-title-color {
    font-weight: bold;
    color: #6b6b6b;
}
