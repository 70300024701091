.rtb-main {
    font-family: 'HelveticaNeueW01-76Bold', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-top: 70px;
}

.first-section {
    color: #092d57;
    margin-bottom: 10px
}

.second-section {
    color: #999999;
}

.second-section span {
    font-weight: bold;
}

.second-section button {
    color: #092d57;
    background-color: unset;
    border: unset;
    text-decoration: underline;
    font-size: medium;
}

.third-section {
    padding-top: 50px;
    padding-bottom: 30px;
    margin-bottom: 0;
    font-size: medium;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.third-section p:first-child{
    font-weight: bold;
    color: #092d57;
    margin-top: 0;
    margin-bottom: 0;
}

.third-section p:nth-child(2) {
    color: #888888;
    font-size: small;
    margin-top: 5px;
    margin-bottom: 0;
}

.third-section div {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    justify-content: center;
}

.rtb-button {
    width: 100%;
    border-radius: 50%;
    font-size: medium;
}

.rtb-button a:first-child {
    margin-right: 15px;
    width: 20%;
    border-radius: 7px;
    font-size: medium;
}

.rtb-button a:nth-child(2){
    width: 20%;
    border-radius: 7px;
    font-size: medium;
}

/*********************************
* <EligibilityRequirement />
**********************************/
.eligibility-popup-content {
    background-color: #F2F2F2;
    border: 1px solid #f2f2f2;
    width: 900px;
    min-width: 600px;
}

.eligibility-popup-content h5 {
    font-size: 32px;
    font-weight: bold;
    color: #092d57;
    margin-bottom: 32px;
}

.eligibility-popup-content ul {
    padding-left: 23px;
}

.eligibility-popup-content p {
    font-family: "HelveticaNeueW01-65Medi", sans-serif;
    color: #888888;
    font-size: 18px;
    padding-left: 3px;
    margin-top: 0;
    margin-bottom: 7px;
    letter-spacing: 0.12px;
}

.eligibility-popup-content h5:last-child {
    margin-top: 30px;
}

.eligibility-popup-close {
    color: #aaa;
    float: right;
    font-size: 28px;

    padding-right: 30px;
    padding-top: 15px;
}

.eligibility-popup-close:hover,
.eligibility-popup-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.eligibility-popup-text {
    padding-top: 30px;
    padding-left: 100px;
    padding-bottom: 40px;
}

@media (max-width: 991px) {
    .eligibility-popup-content h5 {
        font-size: 21px;
    }

    .eligibility-popup-content p {
        font-size: 14px;
    }

    .eligibility-popup-text {
        padding-left: 100px;
        padding-bottom: 30px;
    }

    .eligibility-popup-content {
        width: 700px;
        margin-top: 60px;
        margin-bottom: 30px;
    }
}
