header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 770px;
    padding: 20px 50px;
    background-color: white;
    z-index: 99;
    -webkit-box-shadow: 0 5px 10px 0 rgba(92,92,92,0.6);
    -moz-box-shadow: 0 5px 10px 0 rgba(92,92,92,0.6);
    box-shadow: 0 5px 10px 0 rgba(92,92,92,0.6);
}

header .container-fluid {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header img {
    width: 130px;
    height: auto;
}

header .links {
    text-align: right;
    padding: 0 100px 0 0;
}

header .links a {
    font-family: 'HelveticaNeueW01-76Bold', sans-serif;
    color: #004F99;
    font-size: 20px;
    font-weight: bold;
    margin-right: 30px;
    text-decoration: none;
}

header .links a:hover {
    color: #323232;
}

header .links a:last-child {
    margin-right: 0;
}
