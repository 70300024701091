.title-font {
    font-size: 20px;
    font-weight: bold;
    color: #6b6b6b;
    padding-bottom: 10px;
}

.desc-container {
    padding: 25px;
    background: #f2f2f2;
    margin-bottom: 20px;
    color: #afafaf;
}

.desc-container:last-child {
    margin-bottom: 0;
}

.contract-container {
    background-color: #F2F2F2;
    border: 1px solid #f2f2f2;
    width: 1000px;
    min-width: 600px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}
.contract-title {
    font-size: 23px;
    color: #092d57;
    margin-left: 50px;
    margin-top: 60px;
}

.contract-subtitle {
    font-size: 18px;
    color: #092d57;
    margin-top: 10px;
}

.contract-body {
    font-size: 13px;
    color: #888888;
    margin: 20px 80px 30px 50px;
    overflow: auto;
    height: 420px;
}

.contract-text {
    padding-left: 30px;
    margin-top: 10px;
}

.contract-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
}

.contract-terms {
    margin-top: 35px;
    margin-bottom: 35px;
}

.contract-terms-accept {
    color: #092d57;
    margin-top: 20px;
    margin-left: 20px;
}
