html, body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}


body {
    background-color: #fff;
    font-family: 'HelveticaNeueW01-55Roma', sans-serif;
    letter-spacing: -0.3px;
    color: #000000;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5 {
    font-family: 'HelveticaNeueW01-76Bold', sans-serif;
    font-weight: bolder;
    color: #000000;
    margin-top: 0;
}

h1 {
    font-size: 90px;
    margin-bottom: 90px;
}

h2 {
    font-size: 60px;
    margin-bottom: 60px;
}

h3 {
    font-size: 50px;
    margin-bottom: 50px;
}

h4 {
    font-size: 45px;
    margin-bottom: 45px;
}

h1 div,
h2 div,
h3 div,
h4 div {
    background-color: #000000;
}

h1 div {
    width: 169px;
    height: 13px;
    margin-bottom: 14px;
}

h2 div {
    width: 109px;
    height: 8.67px;
    margin-bottom: 8px;
}

h3 div {
    width: 88px;
    height: 7px;
    margin-bottom: 8px;
}

h4 div {
    width: 88px;
    height: 7px;
    margin-bottom: 8px;
}

a {
    font-family: 'HelveticaNeueW01-55Roma', sans-serif;
    color: #004F99;
    font-weight: bold;
}

a:hover {
    text-decoration: none;
    color: #323232;
}

a img {
    outline: none;
    border: 0;
    text-decoration: none;
}

a.button {
    display: inline-block;
    padding: 15px 45px;
    border: 2px solid white;
    color: white;
    font-family: 'HelveticaNeueW01-76Bold', sans-serif;
    font-weight: bold;
    font-size: 16px;
    background-color: transparent;
    -webkit-transition: background-color 0.5s, border-color 0.5s;
    transition: background-color 0.5s, border-color 0.5s;
}

a.button:hover {
    background-color: #004F99;
    border-color: #004F99;
}

section, section.container-fluid {
    padding: 50px;
    overflow: hidden;
    position: relative;
}

header+section, header+section.container-fluid {
    padding-top: 130px;
}

.background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

section.background-image h1,
section.background-image h2,
section.background-image h3,
section.background-image h4 {
    color: white;
}

section.background-image h1 div,
section.background-image h2 div,
section.background-image h3 div,
section.background-image h4 div {
    background-color: white;
}

section.background-image-carousel .background-image-carousel-slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

section.background-image-carousel .background-image-carousel-slides .background-image-carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    -webkit-transition: opacity 1s; /* Safari */
    transition: opacity 1s;
}

section.background-image-carousel .background-image-carousel-slides .background-image-carousel-slide.active {
    opacity: 1;
}

section.bg-light-gray {
    background-color: #F2F2F2;
}

section.bg-dark-gray {
    background-color: #E5E5E5;
}

section.bg-very-dark-gray {
    background-color: #999999;
}

section.bg-very-dark-gray h3 {
    color: white;
}

section.bg-very-dark-gray h3 div {
    background-color: white;
}

.hidden-md,
.hidden-sm,
.hidden-xs {
    display: block;
}

.visible-md,
.visible-sm,
.visible-xs {
    display: none;
}

.row {
    margin: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8 .col-9, .col-10, .col-11, .col-12,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    padding: 0;
}

/***********************
* Get In Touch Section
***********************/

section.get-in-touch p {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
}

section.get-in-touch a, div.contact-email a {
    display: inline-block;
    width: 100%;
    padding: 20px;
    color: white;
    text-align: center;
    font-size: 20px;
    background-color: #bbbbbb;
    border-right: 1px solid white;
    text-decoration: none;
    -webkit-transition: background-color 0.3s; /* Safari */
    transition: background-color 0.3s;
}

section.get-in-touch a:hover, div.contact-email a:hover {
    background-color: #004F99;
}

section.get-in-touch a img {
    width: auto;
    height: 20px;
}

section.get-in-touch .help a,
section.get-in-touch .intagram a {
    border-right: 0;
}

section.get-in-touch .row.engage-social {
    margin-top: 25px;
}

.contact-email a.local {
    width: 40%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.contact-email a.local{
    border-left: none;
    font-size: 19px;
}

/***********************
* Back to top arrow
***********************/

#back-to-top-arrow {
    display: none;
    position: fixed;
    right: 0;
    bottom: 100px;
    cursor: pointer;
    background-color: #c2c2c2;
    width: 47px;
    height: 47px;
    z-index: 999;
    background-image: url("../assets/img/arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 60%;
    transition: 0.2s ease;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
}

#back-to-top-arrow:hover {
    background-color: #004f99;
}

/***********************
* Learn More Form
***********************/

.learn-about-advertising {
    border-top: 3px solid white;
    padding: 0 25px;
    max-height: 0;
    overflow: hidden;
}

.learn-about-advertising .learn-title p.heading {
    font-size: 25px;
    font-weight: bold;
    font-family: 'HelveticaNeueW01-76Bold', sans-serif;
}

.learn-about-advertising .learn-title p.details {
    font-size: 16px;
}

.learn-about-advertising .message-sent {
    display: none;
}

.learn-about-advertising .message-sent p.message-sent-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.learn-about-advertising .message-sent p.message-sent-body {
    color: #666;
    font-size: 16px;
}

.learn-about-advertising .close-button {
    text-align: right;
}

.learn-about-advertising .close-button svg {
    width: 100%;
    max-width: 50px;
    height: auto;
    cursor: pointer;
    fill: #cbcbcb;
    -webkit-transition: fill 0.5s;
    transition: fill 0.5s;
}

.learn-about-advertising .close-button svg:hover {
    fill: #004F99;
}

.learn-about-advertising label {
    font-size: 16px;
    color: #666;
    font-family: 'HelveticaNeueW01-55Roma';
    width: 100%;
}

.learn-about-advertising input,
.learn-about-advertising textarea {
    width: 100%;
    border: 0;
    background-color: white;
    padding: 10px;
    color: black;
}

.learn-about-advertising textarea {
    min-height: 100px;
}

.learn-about-advertising input[type=submit] {
    width: auto;
    background-color: #cdc9c1;
    color: white;
    font-size: 16px;
    padding: 10px 30px;
    margin-top: 25px;
    cursor: pointer;
    -webkit-transition: background-color 0.5s, border-color 0.5s;
    transition: background-color 0.5s, border-color 0.5s;
}

.learn-about-advertising input[type=submit]:hover {
    background-color: #004F99;
}

.learn-about-advertising p.error {
    font-size: 16px;
    color: red;
    display: none;
}

.learn-about-advertising .required label {
    color: red;
}

.learn-about-advertising .required p.error {
    display: block;
}

.learn-about-advertising .col {
    padding: 25px;
}

section.get-in-touch-ad-form {
    padding: 0;
}

/***********************
* Media Queries
***********************/

/* md and smaller */
@media (max-width: 1199px) {
    .visible-md {
        display: block;
    }
}

/* sm and smaller */
@media (max-width: 991px) {
    h1 {
        font-size: 60px;
        margin-bottom: 60px;
    }

    h2 {
        font-size: 50px;
        margin-bottom: 50px;
    }

    h3 {
        font-size: 45px;
        margin-bottom: 45px;
    }

    h4 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    h1 div {
        width: 109px;
        height: 8.67px;
        margin-bottom: 8px;
    }

    h2 div {
        width: 88px;
        height: 7px;
        margin-bottom: 8px;
    }

    h3 div {
        width: 88px;
        height: 7px;
        margin-bottom: 8px;
    }

    h4 div {
        width: 70px;
        height: 5px;
        margin-bottom: 8px;
    }

    .hidden-sm {
        display: none;
    }

    .visible-sm {
        display: block;
    }

    .contact-email a.local {
        width: 100%
    }
}

/* xs and smaller */
@media (max-width: 767px)  {
    h1 {
        font-size: 45px;
        margin-bottom: 45px;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    h3 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    h4 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    h1 div {
        width: 88px;
        height: 7px;
        margin-bottom: 8px;
    }

    h2 div {
        width: 60px;
        height: 5px;
        margin-bottom: 5px;
    }

    h3 div {
        width: 60px;
        height: 5px;
        margin-bottom: 5px;
    }

    h4 div {
        width: 60px;
        height: 5px;
        margin-bottom: 5px;
    }

    header {
        padding-left: 25px;
        padding-right: 25px;
    }
    header .links {
        margin-top: 10px;
        text-align: left;
    }

    header .links a {
        font-size: 14px;
        margin-right: 12px;
    }

    section.get-in-touch a {
        border-right: 0;
    }

    section.get-in-touch .column.twitter a,
    section.get-in-touch .column.facebook a {
        border-right: 1px solid white;
    }

    section.get-in-touch .row.engage-social {
        margin-top: 0;
    }

    section.get-in-touch .column.hello,
    section.get-in-touch .column.help,
    section.get-in-touch .column.engage {
        margin-bottom: 25px;
    }

    .hidden-xs {
        display: none;
    }
    .visible-xs {
        display: block;
    }

    .learn-about-advertising {
        padding-top: 0;
        border-top: 0;
    }

    .learn-about-advertising .learn-title,
    .learn-about-advertising .close-button,
    .learn-about-advertising .message-sent {
        padding-top: 0;
    }
}

.key-features-list {
    width: 90%;
    font-size: 16px;
    color: #888888;
    padding:0 0 0 15px;
}

.key-features-sublist {
    list-style-type: disc;
}
