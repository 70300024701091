.loading-bar-container {
    padding-top: 20px;
    padding-bottom: 6px;
    display: flex;
    justify-content: center;
}
.loading-bar-container span {
    font-size: 10px;
    color: #969696;
}

.loading-bar-graph {
    display: flex;
    justify-content: center;
}

.loading-bar-graph-fill {
    height: 8px;
    background-color: #969696;
    margin-right: 1px;
}

.two-bars {
    width: 180px;
}

.three-bars {
    width: 120px;
}
