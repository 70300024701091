* {
    --background-color-1: #0D3BEB;
    --background-color-2: #DCE4FF;
    --header-text-color: #DCE4FF;
    --title-text-color: #0D3BEB;
    --tagline-text-color: #0D278A;
    --optional-text-color: #0D3BEB;

    --logo__left__visibility: visible;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-SemiBold.ttf');
    font-style: normal;
}

.font-preset {
    font-family: Poppins, sans-serif;
    text-align: left;
}

.font-preset .large {
    font-size: 40px;
    letter-spacing: -1px;
    line-height: 93%;
}

.font-preset .small {
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 94%;
}

.preview {
    box-sizing: border-box;
    width: 360px;
    height: 640px;
    background-color: var(--background-color-1);
}

.header {
    box-sizing: border-box;
    height: 73px;
    padding: 18px 24px 0;
    color: var(--header-text-color);
    line-height: 33px;
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 561px;
    background-color: var(--background-color-2);
    margin: 0 8px 8px;
}

.title-n-tagline {
    position: relative;
    height: inherit;
    z-index: 1;
}

.title {
    padding: 10px 16px 11px;
    color: var(--title-text-color);
    max-height: 131px;      /* 3-line limit fallback */
}

.tagline {
    padding: 4px 16px 11px;
    color: var(--tagline-text-color);
}

.logo {
    position: relative;
    z-index: 0;
}

.logo img {
    position: absolute;
    bottom: 0;
}

.logo img:nth-of-type(1) {
    left: 20px;
    bottom: 20px;
    width: 117px;
    visibility: var(--logo__left__visibility);
}

.logo img:nth-of-type(2) {
    right: 7px;
    bottom: 8px;
    width: 167px;
}

.optional {
    color: var(--optional-text-color);
    box-sizing: border-box;
    border-top: 8px solid var(--optional-text-color);
    text-align: left;
    padding: 14px 14px 13px;
    vertical-align: center;
    align-items: center;
}

.wrapper-text {
    padding-bottom: 6px;
    width: 91%;
}

.optional-icon {
    width: 27px;
}

.optional-icon img{
    width: 20px;
}

.dashed_bottom {
    font-size: 8px;
    width: 100%;
    overflow-x: hidden;
    letter-spacing: 1.5px;
    margin: -6px 0 -13px 0;
    opacity: 0.6;
}

.address {
    display: flex;
    align-items: flex-start;
    padding-bottom: 6px;
}

.hours {
    display: flex;
    align-items: flex-start;
    padding-bottom: 7px;
}

.contact {
    display: flex;
    align-items: flex-start;
}

.text-overflow {
    overflow-wrap: break-word;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: clip;
    display: block;
    position: relative;
    -webkit-box-orient: vertical;
}

.up-to-3-lines {
    line-height: 1.08;
    max-height: 122px; /* For 3 lines */
    clip-path: inset(0 0 0 0);
    -webkit-line-clamp: 3;
}

.optional-up-to-2-lines {
    line-height: 1.09em;
    max-height: 45px;
    min-height: 23px;
    max-width: 288px;
}

.optional .text-no-overflow {
    overflow: hidden;
    padding-bottom: 2px;
    width: 91%;
    line-height: 1.09;
    height: calc(1.09em * 1); /* For 1 lines */
}

.preview-button {
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.preview-button label{
    padding: 6px 9px;
}
