.top-div {
    margin-bottom: 30px;
    min-width: 900px;
}

.news-section-1 {
    background-image: url('../assets/img/press-releases.jpg');
    padding-bottom: 230px;
    margin-top: 20px;
    background-size: cover;
    height: 280px;
}

.news-section-div {
    padding-top: 50px;
    color: white;
}

.news-section-1 h1 {
    color: white;
}

.news-section-2 {
    padding: 0 0 0 0;
    display: flex;
    flex-direction: row;
}

.news-post-list {
    padding: 50px;
}

.news-post-list h3 {
    margin-bottom: 15px;
}

.news-post {
    margin-bottom: 10px;
}

.news-post a {
    text-decoration: none;
}

.news-post-title {
    font-size: 30px;
    margin-bottom: 15px;
    color: black;
}

.news-post-date {
    font-size: 12px;
    color: #888888;
    font-weight: 600;
}

.news-post hr {
    margin-left: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: #bababa;
}

@media (max-width: 991px) {
    .news-post hr {
        width: 60px;
    }
}

@media (min-width: 992px) {
    .news-post hr {
        width: 88px;
    }
}

.post-content {
    padding: 60px 47px 75px 63px;
}

.post-content a {
    font-weight: normal;
}

.post-content h3 {
    margin-bottom: 15px;
}

.post-content-section-1 {
    padding-bottom: 10px;
    margin-top: 20px;
    background-size: cover;
    min-height: 400px;
}

.post-content-section-1-div {
    padding-top: 50px;
    color: white;
    width: 85%;
}

.post-content-section-1 h2 {
    color: white;
    font-size: 65px;
}

.post-content-section-1 h2 div{
    background-color: white;
}

.post-content-section-2 {
    padding: 0 0 0 0;
    display: flex;
    flex-direction: row;
}

.post-content-date {
    font-style: italic;
    margin: 25px 0 40px;
    font-size: 16px;
}

.post-content-inner-text {
    width: 85%;
    line-height: 27px;
}

.post-content-inner-text p {
    font-size: 17px;
    margin-bottom: 30px;
}

.sidebar {
    width: 330px;
}

.sidebar-header {
    height: 103px;
    background-color: #f2f2f2;
    padding: 50px 45px 57px 50px;
    font-size: 14px;
    font-weight: 600;
    color: #05519a;
}

.divider {
    width: 235px;
    margin-left: 0;
    margin-right: 0;
    background-color: #fff;
}

.link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.no-decoration {
    text-decoration: none;
}

.social-icons {
    background-color: white;
    height: 25px;
    display: flex;
    flex-direction: row;
}

.social-icon {
    background-color: #adadad;
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-middle {
    margin-left: 0.5px;
    margin-right: 0.5px;
    width: 34%;
}

.social-icon img.icon {
    width: 10px;
    padding-bottom: 1px;
}
