.coming-soon {
    display: flex;
    flex-direction: column;
    padding: 130px 20px 0 40px;
    flex: 1;
    background-color: #e6e6e5;
}

.coming-soon a {
    text-decoration: none;
    margin-left: 6px;
}

.coming-soon p {
    font-weight: bold;
    font-size: medium;
}

.horizontal-line {
    width: 11%;
    height: 8px;
    background-color: white;
    margin-bottom: 1rem;
}
