.spinner-wrapper{
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-direction: column;
    align-items: center;
}

.spinner-text {
    padding: 20px;
}
