.align-left {
    margin-top: 10px;
    text-align: left;
}

.spinner-loading {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.continue-button {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}
